import { useState } from 'react';

import { FeatureProps } from './Feature.types';
import { Action, Container, ContentContainer, TitleContainer, Title } from './Feature.styles';
import { ChevronDownIcon } from '../Icons';
import { IconButton } from '../Icons/Icon';

export const Feature = ({
  children,
  title,
  isOpen = false,
  iconLeft,
  onActionClick,
}: React.PropsWithChildren<FeatureProps>) => {
  const [isCurrentOpen, setIsOpen] = useState(isOpen);
  return (
    <Container $isOpen={isCurrentOpen}>
      <TitleContainer
        onClick={(event) => {
          setIsOpen(!isCurrentOpen);
          onActionClick && onActionClick(event);
        }}
      >
        <Action>
          <IconButton>{iconLeft ? iconLeft : <ChevronDownIcon />}</IconButton>
        </Action>
        <Title>{title}</Title>
      </TitleContainer>
      {isCurrentOpen && <ContentContainer>{children}</ContentContainer>}
    </Container>
  );
};
