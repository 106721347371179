// https://www.kameleoon.com/en/feature-flags-trial

import React from 'react';
import styled from 'styled-components';

import { useRouter } from '../../../hooks/useRouter';

import { Urls } from '../../../routes';
import { Colors } from '../../../styles/colors';
import vipLottie from '../../../assets/lotties/vip-pass.json';
import rocketLottie from '../../../assets/lotties/rocket.json';

import { PrimaryButton, SecondaryButton } from '../../atoms/Button';
import { Feature } from '../../atoms/Feature';
import { CheckIcon, ClearIcon } from '../../atoms/Icons';
import { Lottie } from '../../atoms/Lottie';
import { media } from '../../../styles/media';

export const Features: React.FC<{ hideHeader?: boolean; hideFreeFeatures?: boolean }> = ({
  hideHeader = false,
  hideFreeFeatures = false,
}) => {
  const { navigate } = useRouter();

  return (
    <Container>
      <StickyHeader>
        <Columns $hideFreeFeatures={hideFreeFeatures}>
          {hideHeader ? null : (
            <>
              <Empty />
              <Price>
                <LineWrapper>
                  <IconWrapper>
                    <Lottie key="Free" autoplay={true} animationData={vipLottie} />
                  </IconWrapper>
                  Free
                </LineWrapper>
                <SecondaryButton
                  inline={true}
                  size="small"
                  onClick={() => {
                    navigate(`/${Urls.Login}`);
                  }}
                >
                  Start
                </SecondaryButton>
              </Price>
              <Price>
                <LineWrapper>
                  <IconWrapper>
                    <Lottie key="Premium" autoplay={true} animationData={rocketLottie} />
                  </IconWrapper>
                  Premium
                </LineWrapper>
                <PrimaryButton
                  inline={true}
                  size="small"
                  onClick={() => {
                    navigate(`/${Urls.Checkout}`);
                  }}
                >
                  Upgrade
                </PrimaryButton>
              </Price>
            </>
          )}
        </Columns>
      </StickyHeader>
      <Columns $hideFreeFeatures={hideFreeFeatures}>
        <MainTitle>Key features</MainTitle>

        <DetailedFeature>
          <Feature title="Jobs Boards">How many jobs boards can you manage on your account?</Feature>
        </DetailedFeature>
        {hideFreeFeatures ? null : <NotIncluded>Limited to 3</NotIncluded>}
        <Included>Unlimited</Included>

        <DetailedFeature>
          <Feature title="Jobs">How many jobs application can you manage on your account?</Feature>
        </DetailedFeature>
        {hideFreeFeatures ? null : <NotIncluded>Limited to 20 per board</NotIncluded>}
        <Included>Unlimited</Included>

        <DetailedFeature>
          <Feature title="Events">How many evens can you manage on your account?</Feature>
        </DetailedFeature>
        {hideFreeFeatures ? null : <Included>Unlimited</Included>}
        <Included>Unlimited</Included>

        <DetailedFeature>
          <Feature title="Contacts">How many contacts can you manage on your account?</Feature>
        </DetailedFeature>
        {hideFreeFeatures ? null : <Included>Unlimited</Included>}
        <Included>Unlimited</Included>

        <DetailedFeature>
          <Feature title="Notes">How many notes can you manage on your account?</Feature>
        </DetailedFeature>
        {hideFreeFeatures ? null : <Included>Unlimited</Included>}
        <Included>Unlimited</Included>

        <DetailedFeature>
          <Feature title="Tasks">How many tasks can you manage on your account?</Feature>
        </DetailedFeature>
        {hideFreeFeatures ? null : <Included>Unlimited</Included>}
        <Included>Unlimited</Included>

        <DetailedFeature>
          <Feature title="Resumes">How many resume can you manage on your account?</Feature>
        </DetailedFeature>
        {hideFreeFeatures ? null : <Included>Unlimited</Included>}
        <Included>Unlimited</Included>

        <DetailedFeature>
          <Feature title="Version history">View and restore previous versions of job application when needed.</Feature>
        </DetailedFeature>
        {hideFreeFeatures ? null : (
          <NotIncluded>
            <ClearIcon size={2} />
          </NotIncluded>
        )}
        <Included>Coming soon</Included>
      </Columns>
      <Columns $hideFreeFeatures={hideFreeFeatures}>
        <MainTitle>AI features</MainTitle>

        <DetailedFeature>
          <Feature title="Core Skills">Extract core skills from your uploaded resume (PDF / DOC).</Feature>
        </DetailedFeature>
        {hideFreeFeatures ? null : (
          <NotIncluded>
            <ClearIcon size={2} />
          </NotIncluded>
        )}
        <Included>
          <CheckIcon size={2} />
        </Included>

        <DetailedFeature>
          <Feature title="AI Assistant for Text suggestion">
            Unleash your creativity with AI Assistant support for crafting job descriptions and emails.
          </Feature>
        </DetailedFeature>
        {hideFreeFeatures ? null : (
          <NotIncluded>
            <ClearIcon size={2} />
          </NotIncluded>
        )}
        <Included>
          <CheckIcon size={2} />
        </Included>

        <DetailedFeature>
          <Feature title="AI-Generated Resume">
            Introducing our AI Resume Assistant: Your go-to resource for crafting polished and compelling resumes.
            Receive tailored suggestions, refine your language, and impress recruiters effortlessly. Plus, gain expert
            assistance in creating impactful content that highlights your skills and experiences effectively.
          </Feature>
        </DetailedFeature>
        {hideFreeFeatures ? null : (
          <NotIncluded>
            <ClearIcon size={2} />
          </NotIncluded>
        )}
        <Included>
          <CheckIcon size={2} />
        </Included>

        <DetailedFeature>
          <Feature title="Creating AI-Generated cover letters">
            Meet our AI Assistant for Application/Cover letters: Your secret weapon for crafting standout cover letters.
            Get tailored suggestions, refine your language, and impress potential employers effortlessly. Elevate your
            job applications with our smart writing companion.
          </Feature>
        </DetailedFeature>
        {hideFreeFeatures ? null : (
          <NotIncluded>
            <ClearIcon size={2} />
          </NotIncluded>
        )}
        <Included>
          <CheckIcon size={2} />
        </Included>
      </Columns>
      <Columns $hideFreeFeatures={hideFreeFeatures}>
        <MainTitle>General features</MainTitle>

        <DetailedFeature>
          <Feature title="Create Resume">Easily create a resume with our resume builder.</Feature>
        </DetailedFeature>
        {hideFreeFeatures ? null : (
          <Included>
            <CheckIcon size={2} />
          </Included>
        )}
        <Included>
          <CheckIcon size={2} />
        </Included>

        <DetailedFeature>
          <Feature title="Shared Board Link">
            Easily share your job hunting profile with your agency or recruiter through a convenient link provided by
            us. Cancel anytime at your convenience.
          </Feature>
        </DetailedFeature>
        {hideFreeFeatures ? null : (
          <Included>
            <CheckIcon size={2} />
          </Included>
        )}
        <Included>
          <CheckIcon size={2} />
        </Included>

        <DetailedFeature>
          <Feature title="Shared Job Link">
            Easily share your job application with your agency or recruiter through a convenient link provided by us.
            Cancel anytime at your convenience.
          </Feature>
        </DetailedFeature>
        {hideFreeFeatures ? null : (
          <Included>
            <CheckIcon size={2} />
          </Included>
        )}
        <Included>
          <CheckIcon size={2} />
        </Included>

        <DetailedFeature>
          <Feature title="Kanban View">
            Easily manage your job applications with our Kanban board. Drag and drop your job applications across
            different stages of the job application process.
          </Feature>
        </DetailedFeature>
        {hideFreeFeatures ? null : (
          <Included>
            <CheckIcon size={2} />
          </Included>
        )}
        <Included>
          <CheckIcon size={2} />
        </Included>

        <DetailedFeature>
          <Feature title="GoogleMap View">
            View your job applications on a map and see the distance between your job applications and your home.
          </Feature>
        </DetailedFeature>
        {hideFreeFeatures ? null : (
          <Included>
            <CheckIcon size={2} />
          </Included>
        )}
        <Included>
          <CheckIcon size={2} />
        </Included>

        <DetailedFeature>
          <Feature title="Calendar View">
            View your job applications on a calendar and see the events associated with your job applications.
          </Feature>
        </DetailedFeature>
        {hideFreeFeatures ? null : (
          <Included>
            <CheckIcon size={2} />
          </Included>
        )}
        <Included>
          <CheckIcon size={2} />
        </Included>
      </Columns>
      <Columns $hideFreeFeatures={hideFreeFeatures}>
        <MainTitle>Collaboration features</MainTitle>

        <DetailedFeature>
          <Feature title="Invite on Jobs Board">
            Extend invitations to your agency or recruiter, allowing seamless collaboration on your job hunting profile.
            Enjoy the flexibility to cancel at your convenience. Your invited partners gain access to view your detailed
            job profile and retrieve your resume. They can create new job opportunities on your behalf within your job
            board, adding contact details and events to streamline your job hunt management.
          </Feature>
        </DetailedFeature>
        {hideFreeFeatures ? null : (
          <Included>
            <CheckIcon size={2} />
          </Included>
        )}
        <Included>
          <CheckIcon size={2} />
        </Included>

        <DetailedFeature>
          <Feature title="Invite on Job Application">
            Extend exclusive invitations to your agency or recruiter for seamless collaboration on a specific job
            application. You retain full control with the ability to revoke permissions as needed. Your invited partners
            gain access to view your detailed job profile and retrieve your resume. They can effortlessly modify the job
            application, adding crucial contact details and events, enhancing the efficiency of your job hunt
            management.
          </Feature>
        </DetailedFeature>
        {hideFreeFeatures ? null : (
          <Included>
            <CheckIcon size={2} />
          </Included>
        )}
        <Included>
          <CheckIcon size={2} />
        </Included>
      </Columns>
      <Columns $hideFreeFeatures={hideFreeFeatures}>
        <MainTitle>Support & success</MainTitle>

        <DetailedFeature>
          <Feature title="Email support">Ask for support to our Customer Experience team via email.</Feature>
        </DetailedFeature>
        {hideFreeFeatures ? null : <NotIncluded>Best effort</NotIncluded>}
        <Included>
          <CheckIcon size={2} />
        </Included>
      </Columns>
    </Container>
  );
};

const Container = styled.div`
  position: relative; /* Set a non-static position */
`;

const StickyHeader = styled.div`
  position: sticky;
  top: 40px;
  background-color: ${Colors.White};
  z-index: 2;
  height: 160px;

  ${media.from_mobile`
    height: 200px;
  `}
`;

const Columns = styled.div<{ $hideFreeFeatures: boolean }>`
  display: grid;
  position: relative;

  ${({ $hideFreeFeatures }) =>
    $hideFreeFeatures
      ? `
      grid-template-columns: 1fr 1fr;
    `
      : `
      grid-template-columns: 1fr 1fr 1fr;
    `};
`;

const MainTitle = styled.div`
  background-color: ${Colors.White};
  z-index: 1;
  position: sticky;
  top: 200px;
  grid-column: 1 / -1;
  border-bottom: 1px solid ${Colors.ContrastLight};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.2rem;
  padding: 1rem 0;
  font-weight: bold;
  display: flex;

  ${media.from_mobile`
    top: 240px;
  `}
`;

const Empty = styled.div`
  /* border: 1px solid red; */
  border-right: 1px solid ${Colors.ContrastLight};
  border-bottom: 1px solid ${Colors.ContrastLight};
`;

const Price = styled.div`
  flex: 1 1 0;
  /* border-right: 1px solid ${Colors.ContrastLight}; */
  /* border-bottom: 1px solid ${Colors.ContrastLight}; */
  padding: 1rem;
  display: flex;
  flex-direction: column;

  &:last-child {
    /* border-right: none; */
  }
`;

const DetailedFeature = styled.div`
  border-bottom: 1px solid ${Colors.ContrastLight};
  display: flex;
  align-items: center;
  padding: 1rem 0;
`;

const Included = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: bold;
  color: ${Colors.PrimaryDarkest};
  border-bottom: 1px solid ${Colors.ContrastLight};
`;

const NotIncluded = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: bold;
  color: ${Colors.PrimaryDark};
  border-bottom: 1px solid ${Colors.ContrastLight};
`;

const IconWrapper = styled.div`
  display: none;
  width: 64px;
  height: 64px;
  flex: 0 0 64px;
  ${media.from_mobile`  
    display: block;
  `}
`;

const LineWrapper = styled.div`
  display: flex;
  flex-direction: row;
  font-style: normal;
  font-size: 2rem;
  align-items: center;
  padding: 16px 0;
  ${media.from_mobile`  
    font-size: 2rem;
  `}
`;
