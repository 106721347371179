import styled, { css } from 'styled-components';
import { Colors } from '../../../styles/colors';

export const Container = styled.div<{ $isOpen: boolean }>`
  svg {
    transition: transform 0.2s ease-out;
    transform: rotate(-90deg);
    width: 0.8em;
  }

  ${({ $isOpen }) => {
    if ($isOpen) {
      return css`
        svg {
          color: ${Colors.Contrast};
          transform: rotate(0deg);
        }
      `;
    }
  }};
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const Title = styled.h4`
  font-size: 1rem;
  margin: 0;
  padding: 0;
`;

export const Action = styled.div``;

export const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 2.7rem;
`;
